import { render, staticRenderFns } from "./Horizontal.vue?vue&type=template&id=29491efa&"
import script from "./Horizontal.vue?vue&type=script&lang=js&"
export * from "./Horizontal.vue?vue&type=script&lang=js&"
import style0 from "./Horizontal.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QTooltip,QExpansionItem});
