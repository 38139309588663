import { render, staticRenderFns } from "./UserReq.vue?vue&type=template&id=5bd5239b&scoped=true&"
import script from "./UserReq.vue?vue&type=script&lang=js&"
export * from "./UserReq.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bd5239b",
  null
  
)

export default component.exports
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtnToggle,QForm,QInput,QBtn,QSpinnerHourglass});
