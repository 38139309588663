import { render, staticRenderFns } from "./Workspace.vue?vue&type=template&id=d335c0d8&"
import script from "./Workspace.vue?vue&type=script&lang=js&"
export * from "./Workspace.vue?vue&type=script&lang=js&"
import style0 from "./Workspace.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QField from 'quasar/src/components/field/QField.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QIcon,QBtnDropdown,QBtn,QTooltip,QDialog,QCard,QCardSection,QCardActions,QLinearProgress,QToggle,QField,QSpinner});qInstall(component, 'directives', {ClosePopup});
