import { render, staticRenderFns } from "./Groups.vue?vue&type=template&id=0e4a282a&"
import script from "./Groups.vue?vue&type=script&lang=js&"
export * from "./Groups.vue?vue&type=script&lang=js&"
import style0 from "./Groups.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QIcon,QScrollArea,QBadge,QBtn,QPopupEdit});
