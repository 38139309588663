var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-pa-xl"},[_c('h1',{staticClass:"fs32 lhnormal q-mt-none q-mb-md text-weight-regular"},[_vm._v("\n    Восстановление доступа с помощью Email-а\n  ")]),_c('q-stepper',{ref:"stepper",staticClass:"bg-primary-light wd800 pqstepper",attrs:{"color":"primary","animated":"","active-color":"yellow","done-color":"light-green-12","dark":""},scopedSlots:_vm._u([{key:"navigation",fn:function(){return [_c('q-stepper-navigation',[(_vm.step == 3)?_c('div',{staticClass:"q-mt-lg col",staticStyle:{"max-width":"200px","line-height":"125%","float":"left"},on:{"click":function($event){_vm.accepting = !_vm.accepting}}},[_c('a',{staticClass:"col text-white",staticStyle:{"text-decoration":"none"},attrs:{"href":"https://777.xn--l1adcnfw.xn--p1ai/api/authcookie/","target":"_blank"}},[_c('q-icon',{staticClass:"col-auto q-mr-sm q-mb-lg float-left",attrs:{"name":!(_vm.accepting || _vm.$q.cookies.has('cookieaccepted'))
                  ? 'check_box_outline_blank'
                  : 'check_box',"size":"md"}}),_vm._v("Согласие с политикой конфиденциальности")],1)]):_vm._e(),_c('q-btn',{staticClass:"q-mt-lg",attrs:{"disable":_vm.step == 3 && !(_vm.accepting || _vm.$q.cookies.has('cookieaccepted')),"unelevated":"","no-caps":"","loading":_vm.progress,"color":"accent","label":_vm.step === 1
              ? 'Получить проверочный код'
              : _vm.step === 2
              ? 'Ввести новый пароль'
              : 'Изменить пароль и войти'},on:{"click":function($event){return _vm.nextStep()}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('q-spinner-hourglass',{staticClass:"on-left"}),_vm._v("Обработка запроса\n          ")]},proxy:true}])}),(_vm.step > 1)?_c('q-btn',{staticClass:"q-mt-lg q-ml-md",attrs:{"flat":"","no-caps":"","color":"grey-5","label":"Назад"},on:{"click":function($event){return _vm.$refs.stepper.previous()}}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('q-step',{attrs:{"name":1,"prefix":"1","title":"Введите email,","caption":"привязанный к вашему аккауту","icon":"email","done":_vm.step > 1}},[_c('p',[_vm._v("На указанный email придет письмо с проверочным кодом.")]),_c('q-input',{ref:"recoveryaccount",staticClass:"wd375",attrs:{"outlined":"","bg-color":"white","placeholder":"Email или логин","dense":"","required":"","lazy-rules":"","rules":[
          function (val) { return (val && val.length > 0) || 'Заполните это поле пожалуйста'; },
          function (val) { return !/[а-яА-ЯёЁ]/g.test(val) ||
            'Пожалуйста, не используйте русские буквы'; }
        ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"email"}})]},proxy:true}]),model:{value:(_vm.recoveryaccount),callback:function ($$v) {_vm.recoveryaccount=$$v},expression:"recoveryaccount"}})],1),_c('q-step',{attrs:{"name":2,"prefix":"2","title":"Введите код,","caption":"полученный в письме","icon":"vpn_key","done":_vm.step > 2}},[_c('p',[_vm._v("Проверочный код из полученного письма")]),_c('q-input',{ref:"code",staticClass:"wd270",attrs:{"color":"accent","outlined":"","bg-color":"white","placeholder":"Проверочный код","dense":"","required":"","lazy-rules":"","rules":[
          function (val) { return (val && val.length > 0) || 'Заполните это поле пожалуйста'; },
          function (val) { return !/[а-яА-ЯёЁ]/g.test(val) ||
            'Пожалуйста, не используйте русские буквы'; }
        ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"vpn_key"}})]},proxy:true}]),model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('q-step',{attrs:{"name":3,"prefix":"3","title":"Введите новый пароль","caption":"чтобы продолжить работу","icon":"assignment"}},[_c('p',[_vm._v("Введите новый пароль дважды")]),_c('q-input',{ref:"password",staticClass:"wd270 q-mb-sm float-left q-mr-lg",attrs:{"outlined":"","bg-color":"white","type":_vm.isPwd ? 'password' : 'text',"placeholder":"Пароль","dense":"","required":"","lazy-rules":"","rules":[
          function (val) { return (val && val.length > 0) || 'Пароль не указан'; },
          function (val) { return !/[а-яА-ЯёЁ]/g.test(val) ||
            'Пожалуйста, не используйте русские буквы'; }
        ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"lock"}})]},proxy:true},{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":_vm.isPwd ? 'visibility_off' : 'visibility'},on:{"click":function($event){_vm.isPwd = !_vm.isPwd}}})]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('q-input',{ref:"passwordconfirm",staticClass:"wd270",attrs:{"outlined":"","bg-color":"white","type":_vm.isPwd ? 'password' : 'text',"placeholder":"Пароль (еще раз)","dense":"","required":"","lazy-rules":"","rules":[
          function (val) { return (val && val.length > 0) || 'Пароль не указан'; },
          function (val) { return (val && val == this$1.password) || 'Пароли не совпадают'; },
          function (val) { return !/[а-яА-ЯёЁ]/g.test(val) ||
            'Пожалуйста, не используйте русские буквы'; }
        ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"lock"}})]},proxy:true},{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":_vm.isPwd ? 'visibility_off' : 'visibility'},on:{"click":function($event){_vm.isPwd = !_vm.isPwd}}})]},proxy:true}]),model:{value:(_vm.passwordconfirm),callback:function ($$v) {_vm.passwordconfirm=$$v},expression:"passwordconfirm"}})],1)],1),_c('div',{staticClass:"q-mt-lg"},[_c('h1',{staticClass:"fs32 lhnormal q-mt-xl q-mb-md text-weight-regular"},[_vm._v("\n      Восстановление доступа по списку получателей\n    ")]),_c('div',{staticClass:"wd800 rounded-borders secondmethod q-pa-lg bg-primary-light"},[_c('p',[_vm._v("\n        Данный метод восстановления доступа вы можете использовать, если к\n        вашему аккаунту не привязан адрес электронной почты.\n      ")]),_c('p',[_vm._v("\n        Требуется участие сотрудника техподдержки, поэтому время ответа может\n        составлять до нескольких часов.\n      ")]),_c('p',[_vm._v("\n        Укажите наименование и адрес отправителя, а также наименование 3-х\n        получателей.\n      ")]),_c('q-form',{attrs:{"autocomplete":"off"},on:{"submit":_vm.recoveryByNames}},[_c('q-input',{ref:"name1",staticClass:"wd492 q-mt-lg q-mb-md float-left q-mr-md",attrs:{"outlined":"","bg-color":"white","placeholder":"Наименование и адрес отправителя","dense":"","lazy-rules":"","rules":[
            function (val) { return (val && val.length > 0) || 'Заполните это поле пожалуйста'; }
          ]},model:{value:(_vm.name1),callback:function ($$v) {_vm.name1=$$v},expression:"name1"}}),_c('q-input',{ref:"emailfornames",staticClass:"wd238 q-mt-lg q-mb-md float-left",attrs:{"type":"email","outlined":"","bg-color":"white","placeholder":"Ваш Email","dense":"","lazy-rules":"","rules":[
            function (val) { return (val && val.length > 0) || 'Заполните это поле пожалуйста'; },
            function (val) { return !/[а-яА-ЯёЁ]/g.test(val) ||
              'Пожалуйста, не используйте русские буквы'; }
          ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"email"}})]},proxy:true}]),model:{value:(_vm.emailfornames),callback:function ($$v) {_vm.emailfornames=$$v},expression:"emailfornames"}}),_c('q-input',{ref:"name2",staticClass:"wd238 float-left q-mr-md",attrs:{"outlined":"","bg-color":"white","placeholder":"Наименование получателя №1","dense":"","lazy-rules":"","rules":[
            function (val) { return (val && val.length > 0) || 'Заполните это поле пожалуйста'; }
          ]},model:{value:(_vm.name2),callback:function ($$v) {_vm.name2=$$v},expression:"name2"}}),_c('q-input',{ref:"name3",staticClass:"wd238 float-left q-mr-md",attrs:{"outlined":"","bg-color":"white","placeholder":"Наименование получателя №2","dense":"","lazy-rules":"","rules":[
            function (val) { return (val && val.length > 0) || 'Заполните это поле пожалуйста'; }
          ]},model:{value:(_vm.name3),callback:function ($$v) {_vm.name3=$$v},expression:"name3"}}),_c('q-input',{ref:"name4",staticClass:"wd238 float-left",attrs:{"outlined":"","bg-color":"white","placeholder":"Наименование получателя №3","dense":"","lazy-rules":"","rules":[
            function (val) { return (val && val.length > 0) || 'Заполните это поле пожалуйста'; }
          ]},model:{value:(_vm.name4),callback:function ($$v) {_vm.name4=$$v},expression:"name4"}}),_c('q-btn',{ref:"btnfornames",staticClass:"q-mt-md wd238",attrs:{"type":"submit","unelevated":"","no-caps":"","disabled":_vm.btndisabled,"color":"accent","label":_vm.btntext}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }