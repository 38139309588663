import jsPDF from "jspdf";
export default class CommonTemplate {
  constructor() {
    this.doc = new jsPDF();
  }
  getJSPDFObject() {
    return this.doc;
  }
  FontCompress(text, width, startFontSize) {
    let memory = startFontSize;
    this.doc.setFontSize(startFontSize);
    let sname = this.doc.splitTextToSize(text, 242);
    while (sname.length > 1) {
      startFontSize = startFontSize - 0.3;
      this.doc.setFontSize(startFontSize);
      sname = this.doc.splitTextToSize(text, 242);
    }
    return {
      oldFont: memory,
      newFont: startFontSize
    };
  }
  print(browser, mass = 0) {
    if (mass == 1) {
      this.doc.deletePage(1);
    }
    let bname = browser.name.toLowerCase();
    let bversion = browser.version;
    if (navigator.userAgent.includes('Edg')) {
      this.doc.autoPrint();
      const blob = this.doc.output("blob");
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    }
    else if (
      bname == "explorer" ||
      bname == "internet explorer" ||
      bname == "microsoft edge" ||
      bname == "ie"
    ) {
      this.doc.autoPrint();
      let PDFjsNewWindow = window.open(
        "/pdfviewer/web/viewer.html?file=" + this.doc.output("bloburl")
      );
      //this.doc.autoPrint();
      //this.doc.output("pdfjsnewwindow", {
      //  pdfJsUrl: "pdfviewer/web/viewer.html",
      //  filename: "Конверт.pdf"
      //});
    } else if (bname == "Firefox OS" || bname == "Firefox") {
      this.doc.autoPrint();
      var string = this.doc.output("datauristring");
      var x = window.open();
      x.document.open();
      x.document.location = string;
      setTimeout(function() {
        window.URL.revokeObjectURL(string);
      }, 100);
    } else {
      this.doc.autoPrint();
      var blob_iframe = document.querySelector("#blobpdf");
      blob_iframe.src = this.doc.output("bloburl");
    }
  }
  save(filename = "PrinList", mass = 0) {
    if (mass == 1) {
      this.doc.deletePage(1);
    }
    this.doc.save(filename);
  }
}
