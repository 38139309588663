var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row q-mb-lg"},[_c('div',{staticClass:"col-5 text-h4"},[_vm._v("Счета и акты")]),_c('div',{staticClass:"col-7"},[_c('q-btn',{staticClass:"float-right q-ml-md",attrs:{"no-caps":"","label":"Удалить счёт","disable":!_vm.billsnotpayed,"color":"white","flat":""},on:{"click":function($event){$event.preventDefault();return _vm.deleteBill()}}}),_c('q-btn',{staticClass:"float-right createbillbtn",attrs:{"loading":_vm.billcreateprogress,"color":"secondary","unelevated":"","no-caps":""},on:{"click":function($event){$event.preventDefault();_vm.billcreateprogress = true;
          _vm.createBill();}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('q-spinner-hourglass',{staticClass:"on-left"}),_vm._v("Счёт формируется..\n        ")]},proxy:true}])},[_vm._v("\n        Создать счёт\n        ")])],1)]),(_vm.bills != '')?_c('q-table',{staticClass:"no-border",attrs:{"dark":"","table-class":"bg-primary-light billtable","table-header-class":"boldheader","pagination":{
      rowsPerPage: 10
    },"flat":"","dense":"","hide-bottom":"","bordered":"","data":_vm.bills,"columns":_vm.billcolumns,"row-key":"name","separator":"cell","square":""},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('q-tr',{attrs:{"props":props}},[_c('q-td',{key:"billnumber",staticClass:"q-pa-sm",attrs:{"props":props}},[_vm._v("\n          "+_vm._s(props.row.billnumber + " от " + props.row.billdate)+"\n          "),_c('q-btn',{attrs:{"size":"md","flat":"","round":"","color":"accent","icon":"print","target":"_blank"},on:{"click":function($event){return _vm.printDoc({
                doctype: 'bill',
                number: props.row.billnumber,
                date: props.row.billdate
              })}}})],1),_c('q-td',{key:"billstatus",attrs:{"props":props}},[_vm._v(_vm._s(props.row.billpayed > 0 ? "Оплачен" : "Не оплачен"))]),_c('q-td',{key:"billpayedpp",attrs:{"props":props}},[_vm._v(_vm._s(props.row.billpayedpp > 0
            ? props.row.billpayedpp + " от " + props.row.billpayeddate
            : "---"))]),_c('q-td',{key:"sfstatus",attrs:{"props":props}},[_vm._v("\n          "+_vm._s(props.row.sfstatus > 0
              ? props.row.sfnumber + " от " + props.row.sfdate
              : "---")+"\n          "),(props.row.sfstatus > 0)?_c('q-btn',{attrs:{"size":"md","flat":"","round":"","color":"accent","icon":"print"},on:{"click":function($event){return _vm.printDoc({
                doctype: 'act',
                number: props.row.sfnumber,
                date: props.row.sfdate
              })}}}):_vm._e()],1)],1)]}}],null,false,169028424)}):_vm._e(),_c('p',{staticClass:"q-mt-lg"},[_vm._v("\n    * Отчетные документы формируются в последний календарный день квартала\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }