import { render, staticRenderFns } from "./HSlider.vue?vue&type=template&id=27545398&"
import script from "./HSlider.vue?vue&type=script&lang=js&"
export * from "./HSlider.vue?vue&type=script&lang=js&"
import style0 from "./HSlider.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QBadge,QSlider});
