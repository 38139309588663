import { render, staticRenderFns } from "./PostTypeDialog.vue?vue&type=template&id=188e125c&"
import script from "./PostTypeDialog.vue?vue&type=script&lang=js&"
export * from "./PostTypeDialog.vue?vue&type=script&lang=js&"
import style0 from "./PostTypeDialog.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QBtnToggle,QOptionGroup,QToggle,QInput,QSelect,QCardActions,QBtn});
