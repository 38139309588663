var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('q-input',{staticClass:"q-mb-md no-shadow appendgroup",attrs:{"dense":"","dark":"","standout":"","no-shadow":"","bg-color":"primary-light-imp","label":"Введите название группы"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createGroup($event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"add_box","color":"secondary"},on:{"click":_vm.createGroup}})]},proxy:true}]),model:{value:(_vm.newname),callback:function ($$v) {_vm.newname=$$v},expression:"newname"}}),_c('q-scroll-area',{staticClass:"tgroupsarea bg-primary-light q-pa-md",attrs:{"thumb-style":_vm.trthumbStyle}},_vm._l((_vm.groups),function(group,index){return _c('div',{key:index,staticClass:"grouplist bg-primary-light q-px-xs q-pt-sm q-pb-sm"},[_vm._v("\n      "+_vm._s(group.gname.length > 18
          ? group.gname.slice(0, 18) + ".."
          : group.gname)+"\n      "),_c('q-badge',{directives:[{name:"show",rawName:"v-show",value:(_vm.groupscounter.get(group.id) > 0),expression:"groupscounter.get(group.id) > 0"}],staticClass:"q-ml-sm",attrs:{"color":"primary","transparent":"","label":_vm.groupscounter.get(group.id)}}),_c('q-btn',{staticClass:"float-right bg-accent bold-icon",attrs:{"round":"","color":"white","icon":"remove","size":"7px","flat":"","unelevated":""},on:{"click":function($event){return _vm.deleteGroup(group.id)}}}),_c('q-btn',{staticClass:"float-right q-mr-sm bg-secondary",attrs:{"round":"","color":"white","icon":"edit","size":"7px","flat":"","unelevated":""},on:{"click":function($event){_vm.selected = group.gname}}},[_c('q-popup-edit',{attrs:{"content-class":"bg-yellow-3 text-white","validate":function (val) { return val.length > 0; }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var initialValue = ref.initialValue;
          var value = ref.value;
          var emitValue = ref.emitValue;
          var validate = ref.validate;
          var set = ref.set;
          var cancel = ref.cancel;
return [_c('q-input',{attrs:{"autofocus":"","dense":"","value":_vm.selected,"hint":"Новое название группы","rules":[function (val) { return validate(value) || 'Введите название'; }]},on:{"input":emitValue},scopedSlots:_vm._u([{key:"after",fn:function(){return [_c('q-btn',{attrs:{"flat":"","dense":"","color":"accent","icon":"cancel"},on:{"click":function($event){$event.stopPropagation();return cancel($event)}}}),_c('q-btn',{attrs:{"flat":"","dense":"","color":"secondary","icon":"check_circle","disable":validate(value) === false || initialValue === value},on:{"click":[function($event){return _vm.changeGroup(group.id)},function($event){$event.stopPropagation();return set($event)}]}})]},proxy:true}],null,true)})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }